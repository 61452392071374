import React from "react";
import "./global.css";
import "swiper/css";
import "swiper/css/pagination";
import "./ThirdSection.css";

const UrbanNature = () => {

    const cards = [
        { smallText: "Land (In Acres)", bigText: "1.35" },
        { smallText: "Structure", bigText: "B+G+4" },
        { smallText: "RERA Approved", bigText: "BBMP" },
        { smallText: "Undivided Share", bigText: "41%" },
        { smallText: "Open Space", bigText: "55%" },
        { smallText: "Carpet Area", bigText: "70%" },
    ];
    
    return (
        <div className="relative min-h-[200px]"> 
            <div className="gradient-background">
            <div className="text-section relative px-6 pt-10 md:px-28 md:pt-16 min-h-[200px]">
                <div className="flex flex-col gap-4 pt-4 pb-4 text-center md:w-full md:gap-6 md:py-12 md:pt-12 md:text-left min-h-[150px]">
                    <p className="absolute z-[999] mt-20 mr-6 text-xs font-normal leading-[1.45rem] md:text-[20px] md:px-[13rem] md:text-center md:!leading-[2.5rem]">
                        Nestled amidst the vibrant cityscape of Kodigehalli Main Road, Aryav Windfields offers a tranquil escape from the city's hustle
                        and bustle. Spread across 1.35 acres, this exclusive development provides a tranquil haven overlooking the picturesque Sadaramangala
                        Lake.
                    </p>    
                </div>
                </div>
                <div className="md:w-full md:items-center md:justify-center md:flex">
                <div className="grid grid-cols-3 md:grid-cols-2 md:px-28 md:pb-24 md:pt-12 lg:grid-cols-3 gap-4 md:gap-8 px-8 pt-5 pb-12 md:w-[59rem]">
                {cards.map((card, index) => (
                    <div
                        key={index}
                        className="bg-[#F5F2EB] md:justify-center md:w-[13rem] md:h-[10rem] shadow-lg rounded-lg px-2 py-5 flex flex-col gap-1 items-center"
                    >
                        <p className="text-black opacity-50 text-[0.5rem] md:text-sm text-center">
                            {card.smallText}
                        </p>
                        <p className="text-black text-center text-2xl md:text-4xl font-semibold">
                            {card.bigText}
                        </p>
                    </div>
                ))}
                    </div>
                    </div>
            </div>
            
        </div>
    );
};

export default UrbanNature;
